import React, { useState } from "react";
import Header from "../components/Header";
import CreateOrder from "./CustomOrder/CreateOrder";
import Orders from "./CustomOrder/Orders";

const CustomOrder = (props) => {
  const [tab, setTab] = useState("orders");

  const changeTab = (tab) => {
    setTab(tab);
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="d-flex">
                  <button
                    className={`btn ${
                      tab === "orders" ? "btn-primary" : "btn-outline-primary "
                    } text-white mr-2`}
                    onClick={() => setTab("orders")}
                  >
                    Orders
                  </button>
                  <button
                    className={`btn ${
                      tab === "create" ? "btn-primary" : "btn-outline-primary"
                    } text-white`}
                    onClick={() => setTab("create")}
                  >
                    Create Order
                  </button>
                </div>

                {tab === "create" && <CreateOrder changeTab={changeTab} />}
                {tab === "orders" && <Orders />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomOrder;
