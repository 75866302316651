import React, { useState, useRef } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Alert from "../components/Alert";
import ReCAPTCHA from "react-google-recaptcha";

const Reset = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const recaptchaRef = useRef(null);

  const reset = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    setLoader(<Loader />);

    if (e.target.newPassword.value !== e.target.confirmPassword.value) {
      setLoader("");
      setMessage(<Alert className="danger" message="Passwords do not match" />);
      return;
    }

    const data = {
      email: localStorage.getItem("email"),
      newPassword: e.target.newPassword.value,
      OTP: e.target.otp.value,
      captchaToken,
    };

    await axios
      .post(server + "/api/v1/auth/resetPassword", data)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        Cookies.set("token", res.data.accessToken);
        window.location.href = "/";
        localStorage.removeItem("email");
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src="/logo.svg"
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="card">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Reset Password</h4>
                      <div className="nk-block-des">
                        Enter the otp sent to your email and reset your password
                      </div>
                    </div>
                  </div>
                  {message}
                  <form onSubmit={reset}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          OTP
                        </label>
                      </div>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        id="default-01"
                        name="otp"
                        placeholder="Enter OTP "
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          New Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <span
                          className="form-icon form-icon-right "
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <em className="passcode-icon icon-show icon ni ni-eye" />
                          ) : (
                            <em className="passcode-icon icon-show icon ni ni-eye-off" />
                          )}
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control form-control-lg"
                          id="password"
                          name="newPassword"
                          placeholder="Enter your new password"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Confirm New Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <span
                          className="form-icon form-icon-right "
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <em className="passcode-icon icon-show icon ni ni-eye" />
                          ) : (
                            <em className="passcode-icon icon-show icon ni ni-eye-off" />
                          )}
                        </span>
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          className="form-control form-control-lg"
                          id="password"
                          name="confirmPassword"
                          placeholder="Enter your new password"
                        />
                      </div>
                    </div>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_KEY}
                      className="w-100 mb-3"
                    />
                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-primary btn-block"
                        type="submit"
                      >
                        Reset {loader}
                      </button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    {" "}
                    Already have an account? <Link to="/">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>

      {/* main @e */}
    </div>
  );
};

export default Reset;
