import React, { useState, useRef } from "react";
import axios from "axios";
import { server, RECAPTCHA_KEY } from "../env";
import Loader from "../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../components/Alert";
import ReCAPTCHA from "react-google-recaptcha";

const Forgot = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const recaptchaRef = useRef(null);

  const forgot = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.getValue();
    recaptchaRef.current.reset();
    setLoader(<Loader />);
    const data = {
      email: e.target.email.value,
      captchaToken,
    };

    await axios
      .post(server + "/api/v1/auth/forgotPassword", data)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        localStorage.setItem("email", data.email);
        setTimeout(() => {
          navigate("/reset");
        }, 3000);
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src="/logo.svg"
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="card">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Forgot Password?</h4>
                      <div className="nk-block-des">
                        Enter your email to get reset password OTP on your
                        registered email.
                      </div>
                    </div>
                  </div>
                  {message}
                  <form onSubmit={forgot}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          Email
                        </label>
                      </div>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="default-01"
                        name="email"
                        placeholder="Enter your email address "
                      />
                    </div>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_KEY}
                      className="w-100 mb-3"
                    />

                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-primary btn-block"
                        type="submit"
                      >
                        Send OTP {loader}
                      </button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    {" "}
                    Already have an account? <Link to="/">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>

      {/* main @e */}
    </div>
  );
};

export default Forgot;
