import React, { useEffect, useState } from "react";
import { server, config } from "../../env";
import axios from "axios";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
const FormField = ({
  label,
  type = "text",
  name,
  value,
  onChange,
  children,
}) => (
  <div className="form-group mb-3">
    <label htmlFor={name} className="form-label">
      {/* if the label is Street then it should be  */}
      {label === "Street"
        ? "Street (maximum of 35 characters)"
        : label === "Street2"
        ? "Street 2 (optional) (maximum of 35 characters)"
        : label === "State"
        ? "State (optional)"
        : label === "Email"
        ? "Email (optional)"
        : label === "units"
        ? "Units"
        : label === "description"
        ? "Item Description"
        : label === "weight"
        ? "Total Weight (lbs)"
        : label === "value"
        ? "Total Value"
        : label === "tariff"
        ? "Tariff Number"
        : label}
    </label>
    <div className={type === "checkbox" ? "form-check" : "input-group"}>
      <input
        className={type === "checkbox" ? "form-check-input" : "form-control"}
        type={type}
        id={name}
        name={name}
        checked={type === "checkbox" ? value : undefined}
        value={type !== "checkbox" ? value : undefined}
        onChange={onChange}
      />
      {type === "checkbox" && (
        <label className="form-check-label" htmlFor={name}>
          {label}
        </label>
      )}
      {children}
    </div>
  </div>
);

const CreateOrder = ({ changeTab }) => {
  const [formData, setFormData] = useState({
    // Sender Info
    fromName: "",
    fromStreet: "",
    fromStreet2: "",
    fromCity: "",
    fromState: "",
    fromZip: "",
    fromPhone: "",
    fromEmail: "",
    fromCountry: "",
    // Receiver Info
    toName: "",
    toStreet: "",
    toStreet2: "",
    toCity: "",
    toState: "",
    toZip: "",
    toPhone: "",
    toEmail: "",
    toCountry: "",
    // Package Details
    weight: "",
    length: "",
    width: "",
    height: "",

    // Default units
    weightUnit: "lbs",
    dimensionUnit: "inch",

    specialNotes: "",
    signature: false,
    pickup: false,
    pickupDate: new Date().toISOString().split("T")[0],
    pickupTime: new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }),
    pickupInfo: {
      name: "",
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      country: "",
    },
  });

  const [loader, setLoader] = useState("");
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [shipmentPurpose, setShipmentPurpose] = useState("Gift");
  const [currency, setCurrency] = useState("USD");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith("pickupInfo")) {
      setFormData({
        ...formData,
        pickupInfo: {
          ...formData.pickupInfo,
          [name.replace("pickupInfo.", "")]:
            type === "checkbox" ? checked : value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const toggleWeightUnit = () => {
    setFormData({
      ...formData,
      weightUnit: formData.weightUnit === "kg" ? "lbs" : "kg",
      dimensionUnit: formData.dimensionUnit === "cm" ? "in" : "cm",
    });
  };

  const handleSubmit = async (e) => {
    setLoader(<Loader />);
    e.preventDefault();

    // if enter is pressed dont submit the form
    if (e.key === "Enter") {
      return;
    }

    const completeFormData = {
      ...formData,
      invoiceItems,
      shipmentPurpose,
      currency,
    };

    await axios
      .post(`${server}/api/v1/fed/create-order`, completeFormData, config)
      .then((res) => {
        setLoader("");
        toast.success(res.data.message);
        changeTab("orders");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader("");
      });

    console.log("Complete Form Data:", completeFormData);
  };

  const renderToggle = (toggleFunction, currentUnit) => (
    <div className="input-group-append">
      <button
        className="btn btn-outline-secondary"
        type="button"
        onClick={toggleFunction}
      >
        {currentUnit.toUpperCase()}
      </button>
    </div>
  );
  // Dynamically generate form fields
  const renderFormFields = (section) => {
    return Object.entries(formData)
      .filter(([key]) => key.startsWith(section))
      .map(([key, value]) => (
        <FormField
          key={key}
          label={key
            .replace(section, "")
            .replace(/([A-Z])/g, " $1")
            .trim()} // Add spaces before capitals and trim
          name={key}
          value={value}
          onChange={handleChange}
          type={
            key.includes("Email")
              ? "email"
              : key.includes("Phone")
              ? "number"
              : "text"
          }
        />
      ));
  };

  const renderPickupInfoFields = () => {
    return Object.entries(formData.pickupInfo).map(([key, value]) => (
      <FormField
        key={key}
        label={
          key
            .replace(/([A-Z])/g, " $1")
            .trim()
            .charAt(0)
            .toUpperCase() +
          key
            .replace(/([A-Z])/g, " $1")
            .trim()
            .slice(1)
        }
        name={`pickupInfo.${key}`}
        value={value}
        onChange={handleChange}
        type="text" // All fields are text for simplicity, adjust as needed
      />
    ));
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-4">
        {/* Sender Information */}
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title text-center">
                Sender Information (Use Fake Info)
              </h4>
            </div>
            <div className="card-body">{renderFormFields("from")}</div>
          </div>
        </div>
        {/* Receiver Information */}
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title text-center">Receiver Information</h4>
            </div>
            <div className="card-body">{renderFormFields("to")}</div>
          </div>
        </div>
        {/* Package Details */}
        <div className="col-md-12">
          <div className="card mt-4">
            <div className="card-header">
              <h4 className="card-title text-center">Package Details</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <FormField
                    label="Weight"
                    name="weight"
                    value={formData.weight}
                    onChange={handleChange}
                  >
                    {renderToggle(toggleWeightUnit, formData.weightUnit)}
                  </FormField>
                </div>
                <div className="col-4">
                  <FormField
                    label="Length"
                    name="length"
                    value={formData.length}
                    onChange={handleChange}
                  >
                    {renderToggle(toggleWeightUnit, formData.dimensionUnit)}
                  </FormField>
                </div>
                <div className="col-4">
                  <FormField
                    label="Width"
                    name="width"
                    value={formData.width}
                    onChange={handleChange}
                  >
                    {renderToggle(toggleWeightUnit, formData.dimensionUnit)}
                  </FormField>
                </div>
                <div className="col-4">
                  <FormField
                    label="Height"
                    name="height"
                    value={formData.height}
                    onChange={handleChange}
                  >
                    {renderToggle(toggleWeightUnit, formData.dimensionUnit)}
                  </FormField>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group mt-3 mb-3">
        <label htmlFor="specialNotes" className="form-label">
          Special Notes:
        </label>
        <input
          className="form-control"
          id="specialNotes"
          name="specialNotes"
          value={formData.specialNotes}
          onChange={handleChange}
          placeholder="Special Notes (optional)"
        ></input>
      </div>

      <button type="submit" className="btn btn-primary mt-3">
        Create Order {loader}
      </button>
    </form>
  );
};

export default CreateOrder;
