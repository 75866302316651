import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../components/Loader";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CreateQuickOrder = (props) => {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [Type_id, setType_id] = useState("");
  const [Weight, setWeight] = useState(0);
  const [types, setTypes] = useState([]);
  const [loader, setLoader] = useState("");
  const [address, setAddress] = useState({});
  const [address1, setAddress1] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [activeType, setActiveType] = useState({});
  const [signature, setSignature] = useState(false);
  const [From, setFrom] = useState({
    FromName: "",
    FromStreet: "",
    FromStreet2: "",
    FromCity: "",
    FromState: "",
    FromZip: "",
    FromCompany: "",
    FromCountry: "",
  });
  const [To, setTo] = useState({
    ToName: "",
    ToStreet: "",
    ToStreet2: "",
    ToCity: "",
    ToState: "",
    ToZip: "",
    ToCompany: "",
    ToCountry: "",
  });

  useEffect(() => {
    readLabelTypes();
    getAddresses();
  }, []);

  const getAddresses = async () => {
    await axios
      .get(server + "/api/v1/address/readAll", config)
      .then((res) => {
        setAddresses(res.data.addresses);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setFrom({
      FromName: address.name,
      FromStreet: address.street,
      FromStreet2: address.street2,
      FromCity: address.city,
      FromState: address.state,
      FromZip: address.zip,
      FromCompany: address.company,
      FromCountry: address.country,
    });
  }, [address]);
  useEffect(() => {
    setTo({
      ToName: address1.name,
      ToStreet: address1.street,
      ToStreet2: address1.street2,
      ToCity: address1.city,
      ToState: address1.state,
      ToZip: address1.zip,
      ToCompany: address1.company,
      ToCountry: address1.country,
    });
  }, [address1]);

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read", config)
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // find the type of label and max weight

    if (!Type_id || !Weight) return;
    const getPrice = async () => {
      const data = {
        labelType: Type_id,
        weight: Weight,
      };

      await axios
        .post(server + "/api/v1/order/price", data, config)
        .then((res) => {
          setFinalPrice(res.data.price);
        })
        .catch((err) => {
          console.log(err);
          setFinalPrice(0);
        });
    };

    getPrice();
  }, [Type_id, Weight]);

  const createOrder = async (e) => {
    e.preventDefault();
    if (isRequestSent) return;
    setIsRequestSent(true);
    setLoader(<Loader />);

    const data = {
      signature: false,
      quantity: e.target.quantity.value,
      labelType: e.target.type.value,
      design: e.target.design?.value || "",
      Weight: e.target.Weight.value,
      ToName: To.ToName,
      ToStreet: To.ToStreet,
      ToStreet2: To.ToStreet2,
      ToCity: To.ToCity,
      ToState: To.ToState,
      ToZip: To.ToZip,
      ToCompany: To.ToCompany,
      ToCountry: "US",
      FromName: From.FromName,
      FromStreet: From.FromStreet,
      FromStreet2: From.FromStreet2,
      FromCity: From.FromCity,
      FromState: From.FromState,
      FromZip: From.FromZip,
      FromCompany: From.FromCompany,
      FromCountry: "US",
    };

    await axios
      .post(server + "/api/v1/order/create", data, config)
      .then((res) => {
        toast.success("Order created successfully");
        navigate("/");
        setLoader("");
        setIsRequestSent(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setIsRequestSent(false);
        setLoader("");
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className=" d-flex justify-content-between">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">Create Order</h4>
                  </div>
                </div>
              </div>

              <form onSubmit={createOrder}>
                <div className="row mb-3 ">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mb-3 align-items-center">
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label className="form-label">Type</label>
                              <select
                                name="type"
                                id=""
                                className="form-control"
                                onChange={(e) => {
                                  setType_id(e.target.value);
                                  setActiveType(
                                    types.find((type) => {
                                      return type._id === e.target.value;
                                    }).uid
                                  );
                                }}
                              >
                                <option value="">Select type</option>
                                {types.map((type) => (
                                  <option key={type._id} value={type._id}>
                                    {type.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/* <div className="col-lg-4">
                            <div class="form-check align-items-center mt-3">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="flexCheckDefault"
                                name="signature"
                                checked={signature}
                                onChange={(e) => {
                                  setSignature(e.target.checked);
                                }}
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                Signature
                              </label>
                            </div>
                          </div> */}
                          {activeType === "priority" && (
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label className="form-label">Template</label>
                                <select
                                  required
                                  name="design"
                                  className="form-control"
                                >
                                  <option value=""> Select type</option>
                                  <option value="pitney">Pitney Bowes</option>
                                  <option value="indica">Endicia</option>
                                  <option value="evs">EVS</option>
                                </select>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="order-id" className="form-label">
                                Weight
                              </label>
                              <input
                                type="number"
                                name="Weight"
                                id="Weight"
                                className="form-control"
                                placeholder="Weight"
                                required
                                onChange={(e) => {
                                  setWeight(e.target.value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label htmlFor="order-id" className="form-label">
                                Quantity
                              </label>
                              <input
                                type="number"
                                name="quantity"
                                id="quantity"
                                className="form-control"
                                placeholder="quantity"
                                value={quantity}
                                min={1}
                                required
                                onChange={(e) => {
                                  setQuantity(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title text-center">From</h2>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id" className="form-label">
                            Select a saved address
                          </label>
                          <select
                            name="address"
                            id=""
                            className="form-control"
                            onChange={(e) => {
                              const address = addresses.find(
                                (a) => a._id === e.target.value
                              );

                              setAddress(
                                addresses.find((a) => a._id === e.target.value)
                              );

                              // if address is selected then fill the form
                              document.getElementById(
                                "fromAddressData"
                              ).value = `${address.name} \n ${address?.street}, ${address?.street2} \n ${address?.city}, ${address?.state} \n ${address?.zip}`;
                            }}
                          >
                            <option value="">Select address</option>
                            {addresses.map((address) => (
                              <option key={address._id} value={address._id}>
                                {address.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            From Address Data
                          </label>
                          <textarea
                            name="FromAddressData"
                            id="fromAddressData"
                            className="form-control"
                            placeholder={` Sender Name \n Street Address 1, Street Address 2 \n City, State \n Zip`}
                            onChange={(e) => {
                              const data = e.target.value;
                              setFrom({
                                FromName: data?.split("\n")[0],
                                FromStreet: data?.split("\n")[1]?.split(",")[0],
                                FromStreet2:
                                  data?.split("\n")[1]?.split(",")[1]?.trim() ||
                                  "",
                                FromCity: data
                                  ?.split("\n")[2]
                                  ?.split(",")[0]
                                  .trim(),
                                FromState: data
                                  ?.split("\n")[2]
                                  ?.split(",")[1]
                                  .trim(),
                                FromZip: data
                                  ?.split("\n")[3]
                                  ?.split(",")[0]
                                  .trim(),
                              });
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title text-center">To</h2>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id" className="form-label">
                            Select a saved address
                          </label>
                          <select
                            name="address"
                            id=""
                            className="form-control"
                            onChange={(e) => {
                              setAddress1(
                                addresses.find((a) => a._id === e.target.value)
                              );

                              const address = addresses.find(
                                (a) => a._id === e.target.value
                              );

                              // if address is selected then fill the form
                              document.getElementById(
                                "toAddressData"
                              ).value = `${address.name} \n ${address?.street}, ${address?.street2} \n ${address?.city}, ${address?.state} \n ${address?.zip}`;
                            }}
                          >
                            <option value="">Select address</option>
                            {addresses.map((address) => (
                              <option key={address._id} value={address._id}>
                                {address.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="form-group">
                          <label className="form-label">To Address Data</label>
                          <textarea
                            name="ToAddressData"
                            id="toAddressData"
                            className="form-control"
                            placeholder={` Receiver Name \n Street Address 1, Street Address 2 \n City, State \n Zip`}
                            onChange={(e) => {
                              const data = e.target.value;
                              setTo({
                                ToName: data?.split("\n")[0],
                                ToStreet: data?.split("\n")[1]?.split(",")[0],
                                ToStreet2:
                                  data?.split("\n")[1]?.split(",")[1]?.trim() ||
                                  "",
                                ToCity: data
                                  ?.split("\n")[2]
                                  ?.split(",")[0]
                                  .trim(),
                                ToState: data
                                  ?.split("\n")[2]
                                  ?.split(",")[1]
                                  .trim(),
                                ToZip: data
                                  ?.split("\n")[3]
                                  ?.split(",")[0]
                                  .trim(),
                              });
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* show balance and button to create order */}
                <div className="d-flex justify-content-end mt-5">
                  <button
                    className="btn btn-primary btn-xl "
                    type="submit"
                    // disabled={isDisabled}
                  >
                    Create Order ${finalPrice * quantity} {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateQuickOrder;
