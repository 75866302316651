import React from "react";
import { Table as BTable } from "react-bootstrap";

const Table = ({ headers, style, children, maxW }) => {
  return (
    <div
      style={{ overflowX: "auto", maxWidth: maxW || "", ...style }}
      className="papr p-0 w-100"
    >
      <BTable className="c-table table table-bordered">
        <thead>
          <tr>
            {headers?.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </BTable>
    </div>
  );
};

export default Table;
