import Header from "../components/Header";
import { useState, useEffect } from "react";
import Loader from "../components/Loader";
import axios from "axios";
import { Link } from "react-router-dom";
import { server, config } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthStore } from "../stores/AuthStore";

const Deposits = (props) => {
  const { user } = useAuthStore();
  const [deposits, setDeposits] = useState([]);
  const [loader, setLoader] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [cashAppID, setCashAppID] = useState("");
  const [url, setUrl] = useState("");
  const [amount, setAmount] = useState(0);
  const [invoice, setInvoice] = useState({});
  const [cashLoader, setCashLoader] = useState("");
  const [qr, setQr] = useState("");

  const PaypalId = "@YUM001";

  const getDeposits = async () => {
    await axios
      .get(
        `${server}/api/v1/invoices/readAll?page=${page}&limit=${limit}`,
        config
      )
      .then((res) => {
        setDeposits(res.data.invoices);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const AddBalance = async (e) => {
    e.preventDefault();

    const data = {
      amount: e.target.amount.value,
      type: e.target.type.value,
    };

    setLoader(<Loader />);
    await axios
      .post(server + "/api/v1/invoices/create-invoice", data, config)
      .then((res) => {
        setLoader("");
        getDeposits();
        if (data.type === "paypal") {
          setUrl(res.data.data.hosted_url);
          setInvoice(res.data.data.invoice);
          const bootstrap = window.bootstrap;
          var myModal = new bootstrap.Modal(document.getElementById("paypal"), {
            keyboard: false,
          });
          myModal.show();
        } else if (data.type === "cashapp") {
          setUrl(res.data.data.hosted_url);
          setInvoice(res.data.data.invoice);
          const bootstrap = window.bootstrap;
          var myModal = new bootstrap.Modal(
            document.getElementById("cashapp"),
            {
              keyboard: false,
            }
          );
          myModal.show();
        } else {
          window.location.href = res.data.data.hosted_url;
          toast.success("Invoice created successfully");
        }
      })
      .catch((err) => {
        setLoader("");
        if (data.type === "cashapp") {
          // setUrl(res.data.data.hosted_url);
          // setInvoice(res.data.data.invoice);
          const bootstrap = window.bootstrap;
          var myModal = new bootstrap.Modal(
            document.getElementById("cashapp"),
            {
              keyboard: false,
            }
          );
          myModal.show();
        }
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  const verifyCashAppPayment = async (e) => {
    e.preventDefault();
    setCashLoader(<Loader />);

    const params = {
      invoice: invoice._id,
      cashAppUrl: e.target.checkurl.value,
    };

    await axios
      .post(`${server}/api/v1/invoices/verify-cashapp`, params, config)
      .then((res) => {
        setCashLoader("");
        toast.success(res.data.message);
        getDeposits();
      })
      .catch((err) => {
        setCashLoader("");
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  // read cashapp
  const readCashApp = async () => {
    await axios(server + "/api/v1/website/get-cashapp", config)
      .then((res) => {
        setCashAppID(res.data.cashapp.cashappId);
        setQr(res.data.cashapp.qr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    readCashApp();
  }, []);

  useEffect(() => {
    getDeposits();
  }, [page, limit]);

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Deposits</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row g-gs ">
                  <div className="col-lg-4">
                    <div className="card h-100">
                      <div className="card-header">
                        <h5 className="card-title text-success">
                          Current Balance
                        </h5>
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-center align-items-center h-100">
                          <h1
                            className={
                              user?.balance === 0 ? "text-danger" : "text-white"
                            }
                            style={{ fontSize: "4rem" }}
                          >
                            $ {user?.balance?.toFixed(2) || 0}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="card">
                      <div className="card-header">
                        <h4>Add balance</h4>
                      </div>

                      <div className="card-body">
                        {/* show balance */}

                        <form onSubmit={AddBalance}>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Amount</label>
                                <input
                                  min={1}
                                  type="number"
                                  className="form-control"
                                  name="amount"
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Choose payment method</label>
                                <select
                                  className="form-control"
                                  name="type"
                                  required
                                >
                                  <option value="paypal">Paypal</option>
                                  <option value="cashapp">CashApp</option>
                                  {/* <option value="stripe">Stripe</option> */}
                                  {/* <option value="manual">Manual</option> */}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="container-fluid d-flex justify-content-end text-end mt-3">
                            <button
                              className="btn btn-sm btn-secondary px-2 py-1"
                              type="submit"
                            >
                              <i className="ni ni-plus"></i>Add balance {loader}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="card h-100 mt-5">
                      <div className="card-header">
                        <div className="card-title">
                          <h5 className="card-title text-center">
                            <span className="text-primary text-center"></span>
                            <span className="text-center">Invoices</span>
                          </h5>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Payment method</th>
                                <th scope="col">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {deposits.map((deposit) => (
                                <tr key={deposit._id}>
                                  <td>
                                    {new Date(
                                      deposit.createdAt
                                    ).toLocaleString()}
                                  </td>
                                  <td>${deposit.amount}</td>
                                  <td>
                                    {deposit.payment_method === "cashapp"
                                      ? "CashApp"
                                      : deposit.payment_method === "paypal"
                                      ? "Paypal"
                                      : deposit.payment_method === "stripe"
                                      ? "Stripe"
                                      : "Manual"}
                                  </td>
                                  <td>
                                    {deposit.status === "pending" ? (
                                      <span className="badge bg-soft-warning text-warning">
                                        Pending
                                      </span>
                                    ) : deposit.status === "cancelled" ? (
                                      <span className="badge bg-soft-danger text-danger">
                                        Cancelled
                                      </span>
                                    ) : (
                                      <span className="badge bg-soft-success text-success">
                                        Completed
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          <nav>
                            <ul className="pagination">
                              <li
                                className={
                                  page === 1
                                    ? "page-item disabled"
                                    : "page-item"
                                }
                              >
                                <Link
                                  className="page-link"
                                  to=""
                                  onClick={(e) => {
                                    setPage(page - 1);
                                  }}
                                >
                                  Prev
                                </Link>
                              </li>

                              <li
                                className="page-item active"
                                aria-current="page"
                              >
                                <Link className="page-link" to="">
                                  {page}{" "}
                                  <span className="sr-only">(current)</span>
                                </Link>
                              </li>

                              <li
                                className={
                                  page === totalPages
                                    ? "page-item disabled"
                                    : "page-item"
                                }
                              >
                                <Link
                                  className="page-link"
                                  to=""
                                  tabIndex={-1}
                                  onClick={(e) => {
                                    setPage(page + 1);
                                  }}
                                >
                                  Next
                                </Link>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      {/* modal */}
      <div className="modal fade" tabIndex={-1} id="cashapp">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4>CashApp Payment</h4>

              <Link
                to=""
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </Link>
            </div>

            <div className="modal-body">
              <div class="d-flex flex-column align-items-center">
                <img
                  src={server + "/" + qr}
                  alt=""
                  className="img-fluid"
                  style={{
                    height: "200px",
                  }}
                />
                <h3
                  className="mt-3"
                  style={{
                    color: "orange",
                  }}
                >
                  {cashAppID}
                </h3>

                <h6 className="mb-3">
                  <span className="text-muted">Amount:</span>{" "}
                  <span className="text-success">$ {amount}</span>
                </h6>

                <h6>
                  <span className="text-muted">Note:</span>{" "}
                  <span className="text-warning">{url || "sdsdsd"}</span>
                </h6>
                <p
                  className="mt-3 text-muted"
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  {invoice._id}
                </p>

                <div className="border border-bottom mt-5 mb-3"></div>
                <p className="text-muted">
                  Send a CashApp payment to{" "}
                  <span className="text-info">{cashAppID}</span> by manually
                  inputing the CashApp tag or scanning the QR code above. Set
                  the amount to <span className="text-success">$ {amount}</span>{" "}
                  and the note to <span className="text-warning">{url}</span>.
                  When done, enter your Web Receipt URL or just the transaction
                  ID from the URL below and click Process Payment!
                </p>

                <form
                  className="w-100 text-center"
                  onSubmit={verifyCashAppPayment}
                >
                  <div className="form-group mt-3">
                    <input
                      id="checkurl"
                      type="text"
                      name="checkurl"
                      className="form-control w-100"
                      placeholder="https://cash.app/payments/xxxxxxxxxxxxxxxxxxxxxxxxx/receipt"
                    />
                  </div>

                  <button
                    className="btn btn-success mt-2 text-center"
                    type="submit"
                  >
                    Process Payment {cashLoader}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="paypal">
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Paypal Payment</h4>

              <Link
                to=""
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <em className="icon ni ni-cross" />
              </Link>
            </div>

            <div className="modal-body">
              <div class="d-flex flex-column align-items-center">
                <h3
                  className="mt-3"
                  style={{
                    color: "orange",
                  }}
                >
                  {PaypalId}
                </h3>

                <h6 className="mb-3">
                  <span className="text-muted">Amount:</span>{" "}
                  <span className="text-success">$ {amount}</span>
                </h6>

                <h6>
                  <span className="text-muted">Note:</span>{" "}
                  <span className="text-warning">{url || "sdsdsd"}</span>
                </h6>
                <p
                  className="mt-3 text-muted"
                  style={{
                    fontSize: "0.8rem",
                  }}
                >
                  {invoice._id}
                </p>

                <div className="border border-bottom mt-5 mb-3"></div>

                <p className="text-muted">
                  Send a Paypal payment to{" "}
                  <span className="text-info">{cashAppID}</span> by manually
                  inputing the paypal id. Set the amount to{" "}
                  <span className="text-success">$ {amount}</span> and the note
                  to <span className="text-warning">{url}</span>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposits;
