import React from "react";

const Alert = (props) => {
  return (
    <div className={"alert alert-icon alert-" + props.className} role="alert">
      <strong>{props.message}</strong>
    </div>
  );
};

export default Alert;
